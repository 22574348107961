import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import i18n from '../../utils/translate';

const TermosDeUsoP2SPay = (): JSX.Element => {
  const b_containerName = 'termos_de_uso';
  const { t } = useTranslation();

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>
          {t(
            'Termo de adesão plataforma financeira - Play2sell Pay PLAY2SELL S.A.'
          )}
        </title>
        <meta
          name='description'
          content='Olá, bem-vindo (a) à Play2sell! Somos uma plataforma de gamificação.'
        />
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <link rel='canonical' href='https://play2sell.com/terms-of-use/' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='TERMO DE ADESÃO PLATAFORMA FINANCEIRA - Play2sell Pay PLAY2SELL S.A.'
        />
        <meta
          property='og:description'
          content='Olá, bem-vindo (a) à Play2sell! Somos uma plataforma de gamificação.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/p2spay-adhesion-agreement/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2020-11-13T10:13:47-03:00' />
        <meta
          name='twitter:title'
          content='Termo de adesão plataforma financeira - Play2sell Pay PLAY2SELL S.A.'
        />
        <meta
          name='twitter:description'
          content='Olá, bem-vindo (a) à Play2sell! Somos uma plataforma de gamificação.'
        />
      </Helmet>
      <HeaderNav />
      <Header
        b_container={b_containerName}
        title={t('Termo de adesão Play2sell Pay')}
        subtitulo={t('Termo de adesão plataforma financeira')}
      />
      <main className='container-main'>
        <CardText
          b_container={b_containerName}
          b_str='0'
          text={t('Em vigor a partir de: 15 de maio de 2024.') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='1'
          text={
            t(
              'PLAY2SELL S.A., pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 28.864.200/0001-01, com sede na Rua Gomes De Carvalho, 911, 4º Andar, Bairro Vila Olímpia, São Paulo - SP, CEP 04547-003, doravante denominada CONTRATADA, e o CONTRATANTE, que aceita esta versão eletronicamente assinada via plataforma digital, após leitura e concordância com os Termos e Condições de Uso aqui dispostos, doravante denominado CONTRATO para utilização dos serviços da Play2sell Pay.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='2'
          title={t('Aceitação dos Termos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='3'
          text={
            t(
              'O aceite deste contrato pela CONTRATANTE implicará o reconhecimento de que o CONTRATANTE leu, entendeu e aceitou todas as condições e disposições deste instrumento. É responsabilidade do CONTRATANTE assegurar-se de compreender todos os termos aqui contidos antes de sua aceitação. A aceitação eletrônica deste contrato é realizada através da plataforma digital ou aplicativo associado e será considerada juridicamente válida e produzirá todos os efeitos legais como se fosse assinada presencialmente, sujeitando ambas as partes aos termos e condições aqui estabelecidos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='4'
          title={t('Serviços Oferecidos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='5'
          text={`${t(
            'A Play2sell Pay é a plataforma financeira da PLAY2SELL S.A., que oferece serviços de:'
          )}`}
          list={[
            t(
              'a)Transferências financeiras entre contas de mesma titularidade, definidas no ato de cadastro na plataforma pelo CONTRATANTE.'
            ),
            t('b)Criação de Conta Digital na Plataforma Play2sell Pay.'),
            t(
              'c)Emissão de boletos bancários para cobrança de clientes do CONTRATANTE.'
            ),
            t(
              'd)Antecipação de Recebíveis, incluindo a antecipação de comissões, permitindo ao CONTRATANTE obter liquidez financeira de valores a receber de forma antecipada, de acordo com os critérios estabelecidos pela CONTRATADA.'
            ),
            t(
              'e)Gerenciamento e acompanhamento de transações financeiras através de acesso ao site <a href="http://app.p2spay.com.br" target="_blank">app.p2spay.com.br</a> e ou aplicativo móvel.'
            )
          ]}
          listStyle='decimal'
        />
        <CardText
          b_container={b_containerName}
          b_str='6'
          title={t('CLÁUSULA I - ADESÃO E CONDIÇÕES GERAIS DOS SERVIÇOS') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='7'
          title={t('1.1 - Composição do Contrato') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='8'
          text={`${t(
            'Este Contrato é composto por, e inclui integralmente para todos os fins:'
          )}`}
          list={[
            t(
              'a)Formulários eletrônicos ou não eletrônicos, disponibilizados pela CONTRATADA através de seu aplicativo ou website, que são preenchidos e submetidos pelo USUÁRIO;'
            ),
            t(
              'b)Normas de segurança e privacidade estabelecidas pela CONTRATADA'
            )
          ]}
          listStyle='decimal'
        />
        <CardText
          b_container={b_containerName}
          b_str='9'
          title={t('1.1.1 - Exatidão das Informações') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='10'
          text={`${t(
            'O USUÁRIO deve fornecer informações completas, precisas e verdadeiras nos formulários da CONTRATADA, sendo o único responsável por essas informações. O USUÁRIO deve atualizar suas informações sempre que necessário. A falha em manter informações atualizadas pode resultar em:'
          )}`}
          list={[
            t('(i) Suspensão dos serviços;'),
            t(
              '(ii) Suspensão da conta, exigindo a retirada imediata de quaisquer valores;'
            ),
            t(
              '(iii) Responsabilização nas esferas cível, administrativa e criminal, conforme previsto em lei.'
            )
          ]}
          listStyle='decimal'
        />

        <CardText
          b_container={b_containerName}
          b_str='11'
          title={t('1.2 - Autorização para Verificações') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='12'
          text={
            t(
              'O USUÁRIO autoriza a CONTRATADA, diretamente ou através de terceiros, a realizar consultas e solicitações necessárias para compliance e avaliação de risco. Isso inclui, mas não se limita a, verificações junto ao Sistema Financeiro Nacional, Banco Central do Brasil e outros órgãos reguladores nacionais e internacionais para validar a identidade do USUÁRIO e cumprir com as obrigações da Lei de Lavagem de Dinheiro e da Lei Anticorrupção.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='13'
          title={t('1.3 - Consultas de Crédito') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='14'
          text={`${t(
            'O USUÁRIO autoriza a CONTRATADA a acessar sua posição de crédito pessoal e/ou comercial:'
          )}`}
          list={[
            t('(i) a pedido do USUÁRIO para produtos e serviços;'),
            t(
              '(ii) sempre que a CONTRATADA julgar necessário devido ao aumento do risco associado à conta do USUÁRIO.'
            )
          ]}
          listStyle='decimal'
        />
        <CardText
          b_container={b_containerName}
          b_str='15'
          title={t('1.4 - Aprovação de Cadastro') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='16'
          text={
            t(
              'A CONTRATADA reserva-se o direito de não aprovar o cadastro do USUÁRIO para abertura de conta.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='17'
          title={t('1.5 - Elegibilidade para Uso da Conta') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='18'
          text={`${t('A conta pode ser utilizada por:')}`}
          list={[
            t(
              '(i) Pessoas físicas maiores de 18 anos ou, em casos excepcionais, menores de 18 anos com aprovação especial da CONTRATADA;'
            ),
            t(
              '(ii) Pessoas jurídicas devidamente registradas e que operem dentro da legalidade no território nacional, sem quaisquer impedimentos legais.'
            )
          ]}
          listStyle='decimal'
        />
        <CardText
          b_container={b_containerName}
          b_str='19'
          title={t('1.6 - Uso da Conta') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='20'
          text={
            t(
              'O uso da conta pelo USUÁRIO é pessoal e intransferível, destinado exclusivamente para atividades lícitas e de acordo com os termos deste Contrato. O USUÁRIO deve realizar operações somente em seu próprio nome ou como representante legal de uma pessoa jurídica'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='21'
          title={t('1.7 - Segurança de Acesso') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='22'
          text={
            t(
              'O USUÁRIO concorda em manter a confidencialidade e segurança de suas credenciais de acesso, incluindo senhas e logins. A CONTRATADA não se responsabilizará por danos resultantes da utilização indevida dessas credenciais. É obrigação do USUÁRIO notificar a CONTRATADA imediatamente sobre qualquer acesso não autorizado ou suspeita de violação.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='23'
          title={t('CLÁUSULA II - TRANSAÇÕES COM A Play2sell Pay') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='24'
          title={t('2.1 - Operações Permitidas') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='25'
          text={
            t(
              'A qualquer momento, o USUÁRIO poderá, contanto que possua saldo líquido suficiente para cobrir os encargos da operação, solicitar a realização de TRANSAÇÕES através do portal WEB ou aplicativo da CONTRATADA.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='26'
          title={t('2.2 - Processo de Transferência') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='27'
          text={
            t(
              'Ao solicitar uma transferência, o USUÁRIO deve especificar claramente o montante a ser transferido. A CONTRATADA processará a transferência com os montantes disponíveis na Play2sell Pay do USUÁRIO. Cada transação estará sujeita ao pagamento de encargos, que serão debitados diretamente da Play2sell Pay do USUÁRIO, conforme autorização expressa deste.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='28'
          title={t('2.2.1 - Execução de Transferências') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='29'
          text={
            t(
              'Uma vez recebida a ordem de transferência, a CONTRATADA executará a transferência dos montantes disponíveis na Play2sell Pay do USUÁRIO, salvo em casos de força maior ou eventos fortuitos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='30'
          title={t('2.2.2 - Responsabilidade do Usuário') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='31'
          text={
            t(
              'O USUÁRIO é o único responsável pelas instruções fornecidas à CONTRATADA, incluindo as ordens de transferência a partir de sua Play2sell Pay. A CONTRATADA não será responsável por erros de execução baseados em informações incorretas fornecidas pelo USUÁRIO.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='32'
          title={t('2.2.3 - Débitos por Inadimplência') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='33'
          text={
            t(
              'A CONTRATADA pode, de forma irrevogável e irretratável, debitar da Play2sell Pay do USUÁRIO quaisquer valores devidos e não pagos por mais de 90 dias a qualquer subsidiária, afiliada ou controladora da CONTRATADA, no Brasil ou no exterior.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='34'
          title={t('2.3 - Cobertura de Saldo Negativo') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='35'
          text={
            t(
              'Caso a Play2sell Pay do USUÁRIO apresente saldo negativo, a CONTRATADA pode restringir o uso e adotar as medidas cabíveis para recuperar o saldo, incluindo o uso de futuros pagamentos recebidos na conta do USUÁRIO para cobrir o déficit.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='36'
          title={t('2.4 - Indenização') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='37'
          text={
            t(
              'O USUÁRIO concorda em indenizar e manter a CONTRATADA isenta de responsabilidade por transferências de dinheiro errôneas ou mal-sucedidas devido ao fornecimento de informações incorretas.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='38'
          title={t('2.5 - Limitações e Restrições') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='39'
          text={
            t(
              'O uso da Play2sell Pay e dos serviços associados pode estar sujeito a limitações e restrições, que serão devidamente comunicadas ao USUÁRIO através do portal WEB ou do aplicativo da CONTRATADA. Estes limites podem ser ajustados mensalmente pela CONTRATADA conforme sua análise de risco e necessidade operacional.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='40'
          title={t('2.6 - Revisão de Limites') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='41'
          text={
            t(
              'Os limites de movimentação e manutenção de saldo na Play2sell Pay poderão ser revisados e ajustados pela CONTRATADA, necessitando comunicação ao USUÁRIO caso essas mudanças sejam por razões de segurança ou imposições legais.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='42'
          title={t('2.7 - Condições de Uso') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='43'
          text={
            t(
              'O USUÁRIO se compromete a utilizar a Play2sell Pay e demais serviços em conformidade com todas as leis aplicáveis, não utilizando para atividades ilegais, fraudulentas ou que infrinjam os direitos de terceiros. O USUÁRIO também concorda em não sobrecarregar a infraestrutura tecnológica da CONTRATADA e manter suas credenciais seguras e confidenciais.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='44'
          title={t('2.8 - Confidencialidade das Informações') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='45'
          text={
            t(
              'As informações pessoais dos USUÁRIOS coletadas pela CONTRATADA serão tratadas como confidenciais, salvo quando a divulgação for necessária para a execução de transações ou por exigência de autoridades reguladoras ou judiciais.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='46'
          title={t('2.9 - Procuração') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='47'
          text={
            t(
              'O USUÁRIO, ao aceitar os termos deste contrato, outorga mandato à CONTRATADA, conferindo-lhe poderes para agir como sua procuradora na execução das transações descritas e para praticar todos os atos necessários à gestão da Play2sell Pay e execução dos serviços.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='48'
          title={t('CLÁUSULA III - RESPONSABILIDADES') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='49'
          title={t('3.1 - Gestão de Recursos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='50'
          text={
            t(
              'Para fins operacionais e dado que a alocação dos recursos é temporária, os valores creditados pela Play2sell Pay serão mantidos em uma conta bancária sob a titularidade da CONTRATADA, em uma instituição financeira de primeira linha. Esta medida não implica que tais recursos sejam de propriedade da CONTRATADA. Eventuais ganhos ou ônus decorrentes da manutenção desses recursos não serão imputáveis ao USUÁRIO, nem poderão ser por ele reclamados.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='51'
          title={t('3.2 - Liquidez dos Recursos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='52'
          text={
            t(
              'A CONTRATADA assegura que mantém recursos líquidos correspondentes ao saldo das contas dos USUÁRIOS, de acordo com a legislação vigente. Cada conta da Play2sell Pay é exclusiva de seu USUÁRIO e deve ser utilizada apenas para a realização de transações autorizadas.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='53'
          title={t('3.3 - Separação de Recursos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='54'
          text={`${t('O USUÁRIO reconhece e aceita que:')}`}
          list={[
            t(
              '(i) A CONTRATADA mantém seus recursos corporativos separados das contas onde os recursos dos USUÁRIOS são depositados, garantindo que não haja mistura entre estes.'
            ),
            t(
              '(ii) Os recursos na Play2sell Pay dos USUÁRIOS não respondem por obrigações da CONTRATADA, nem podem ser alvo de medidas judiciais como arresto ou sequestro por dívidas da CONTRATADA.'
            ),
            t(
              '(iii) Tais recursos não são considerados ativos da CONTRATADA em casos de falência ou liquidação.'
            ),
            t(
              '(iv) Não podem ser utilizados como garantia por dívidas assumidas pela CONTRATADA.'
            )
          ]}
          listStyle='decimal'
        />
        <CardText
          b_container={b_containerName}
          b_str='55'
          title={t('3.4 - Acesso e Informações da Conta') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='56'
          text={
            t(
              'O USUÁRIO poderá acessar sua Play2sell Pay através do Portal WEB e/ou aplicativos móveis da CONTRATADA, onde poderá verificar seu saldo e extrato de transações dentro do período disponibilizado. A apresentação dessas informações pela CONTRATADA constitui uma prestação de contas formal. Não serão fornecidos extratos impressos ou enviados por e-mail.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='57'
          title={t('3.5 - Legalidade dos Recursos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='58'
          text={
            t(
              'O USUÁRIO declara estar ciente de que as transações realizadas por meio da Play2sell Pay destinam-se exclusivamente para pagamentos e recebimentos em moeda corrente nacional e assegura que todos os recursos creditados são provenientes de fontes lícitas. O USUÁRIO isenta a CONTRATADA de qualquer responsabilidade relativa à origem dos recursos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='59'
          title={
            t(
              '3.5.1 - Os saldos em reais na Play2sell Pay podem ser utilizados unicamente para realizar transações dentro do território brasileiro.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='60'
          title={t('CLÁUSULA IV - ENCARGOS E DESPESAS OPERACIONAIS') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='61'
          title={t('4.1 - Cobrança de Encargos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='62'
          text={
            t(
              'O USUÁRIO está ciente de que a CONTRATADA cobrará encargos para a realização das TRANSAÇÕES realizadas através da Play2sell Pay. Todos os encargos aplicáveis estarão claramente especificados e disponíveis para consulta a qualquer momento no portal WEB da CONTRATADA.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='63'
          title={t('4.2 - Remuneração por Novos Serviços') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='64'
          text={
            t(
              'Caso novos produtos ou serviços sejam disponibilizados pela CONTRATADA, poderá ser instituída uma remuneração específica para esses novos produtos/serviços. Os detalhes sobre valores, termos e condições serão devidamente comunicados ao USUÁRIO antes da sua implementação, permitindo que o USUÁRIO decida sobre a adesão aos novos termos'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='65'
          title={t('4.3 - Modificação de Encargos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='66'
          text={
            t(
              'A CONTRATADA reserva-se o direito de alterar os encargos associados às TRANSAÇÕES descritas em qualquer momento. Qualquer alteração será publicada no portal WEB e comunicada aos USUÁRIOS. Após a divulgação dos novos encargos, o USUÁRIO terá a opção de aceitar as novas condições ou, se não estiver de acordo, cancelar sua Play2sell Pay. O USUÁRIO deve revisar regularmente o portal WEB para se manter atualizado sobre quaisquer alterações nos encargos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='67'
          title={
            t(
              'CLÁUSULA V - OBRIGAÇÕES DO USUÁRIO E LIMITAÇÕES DE USO DA Play2sell Pay'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='68'
          title={t('5.1 - Responsabilidade por Encargos e Obrigações') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='69'
          text={
            t(
              'O USUÁRIO é responsável por todos os encargos, multas, penalidades e outras obrigações financeiras que possam surgir devido à violação deste Termo de Uso ou pelo uso indevido dos produtos ou serviços oferecidos pela CONTRATADA. O USUÁRIO concorda em indenizar e manter indene a CONTRATADA, outros usuários ou terceiros de quaisquer prejuízos resultantes dessas ações.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='70'
          title={t('5.2 - Débito por Valores Devidos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='71'
          text={
            t(
              'Se o USUÁRIO for responsável por qualquer valor devido à CONTRATADA e se o saldo disponível na Play2sell Pay não for suficiente para cobrir o valor total devido, a conta exibirá um saldo negativo. O USUÁRIO deverá reembolsar imediatamente a CONTRATADA utilizando outra forma de pagamento. Em caso de inadimplência, a CONTRATADA poderá tomar medidas para recuperar o valor devido, incluindo a inclusão do nome do USUÁRIO nos órgãos de proteção ao crédito.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='72'
          title={t('5.3 - Medidas Protetivas') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='73'
          text={
            t(
              'Se a CONTRATADA, a seu exclusivo critério, acreditar que o USUÁRIO esteja envolvido em atividades proibidas por este Termo, poderá tomar medidas necessárias para proteger-se, suas afiliadas e outros usuários de riscos associados a tais atividades.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='74'
          title={t('5.4 - Suspensão e Bloqueio') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='75'
          text={`${t('A CONTRATADA pode suspender o uso dos serviços, bloquear o acesso à Play2sell Pay do USUÁRIO, e reter quaisquer fundos se razoavelmente suspeitar que:')}`}
          list={[
            t('(i) O USUÁRIO forneceu informações falsas ou enganosas;'),
            t('(ii) Há violação dos termos deste contrato;'),
            t('(iii) Existe um risco de crédito ou fraude inaceitável;'),
            t('(iv) O USUÁRIO está utilizando os serviços em violação da lei;'),
            t('(v) Há envolvimento em condutas fraudulentas ou ilegais.')
          ]}
          listStyle='decimal'
        />
        <CardText
          b_container={b_containerName}
          b_str='76'
          title={t('5.5 - Ações Corretivas') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='77'
          text={`${t('Entre as ações que a CONTRATADA pode adotar estão:')}`}
          list={[
            t('(i) Encerrar, suspender ou limitar o acesso à Play2sell Pay;'),
            t('(ii) Recusar a execução de serviços;'),
            t(
              '(iii) Reter fundos na Play2sell Pay do USUÁRIO pelo tempo que for considerado necessário.'
            )
          ]}
          listStyle='decimal'
        />
        <CardText
          b_container={b_containerName}
          b_str='78'
          title={t('5.6 - Retenção de Pagamentos') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='79'
          text={
            t(
              'A CONTRATADA pode reter pagamentos na Play2sell Pay do USUÁRIO se identificar um alto risco associado à conta ou às transações realizadas. Os fundos retidos poderão ser liberados após 30 dias, a menos que existam motivos justificados para manter a retenção.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='80'
          title={t('5.7 - Reservas e Limitações') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='81'
          text={
            t(
              'Caso haja um alto nível de risco, a CONTRATADA pode impor reservas sobre os fundos na Play2sell Pay do USUÁRIO, tornando-os temporariamente indisponíveis até que a situação de risco seja resolvida.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='82'
          title={t('5.8 - Rescisão e Suspensão de Serviços') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='83'
          text={
            t(
              'A CONTRATADA reserva-se o direito de rescindir este contrato ou suspender o acesso do USUÁRIO aos serviços a qualquer momento e por qualquer motivo, liberando ao USUÁRIO quaisquer valores disponíveis na Play2sell Pay que não estejam sujeitos a restrição.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='84'
          title={t('5.9 - Conformidade e Uso Adequado') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='85'
          text={
            t(
              'O USUÁRIO deve utilizar a Play2sell Pay e outros serviços da CONTRATADA de maneira que não viole as leis aplicáveis, direitos de terceiros ou os bons costumes. Isso inclui, sem limitação, a proibição de atividades ilegais, fraudulentas ou que violam a propriedade intelectual.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='86'
          title={t('5.10 - Licença de Uso') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='87'
          text={
            t(
              'A CONTRATADA concede ao USUÁRIO uma licença pessoal, limitada, temporária e revogável para usar as soluções tecnológicas associadas à Play2sell Pay, sujeita estritamente aos termos deste contrato.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='88'
          title={
            t('CLÁUSULA VI - RESCISÃO E ENCERRAMENTO DA Play2sell Pay') || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='89'
          title={t('6.1 - Encerramento Voluntário pelo Usuário') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='90'
          text={
            t(
              'O USUÁRIO poderá encerrar sua Play2sell Pay a qualquer momento, seguindo as instruções disponíveis no portal WEB da CONTRATADA. Antes de proceder com o encerramento, o USUÁRIO deverá assegurar-se de que todos os recursos disponíveis em sua Play2sell Pay sejam retirados.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='91'
          title={t('6.2 - Encerramento Durante Investigação') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='92'
          text={
            t(
              'O encerramento da Play2sell Pay pelo USUÁRIO não impedirá a continuidade de quaisquer investigações em andamento relacionadas à conta. Se o USUÁRIO encerrar sua Play2sell Pay durante uma investigação conduzida pela CONTRATADA, esta poderá reter os recursos na conta para proteger seus interesses, os de suas afiliadas ou de terceiros contra riscos de reclamações ou outras obrigações financeiras que possam surgir. O USUÁRIO permanecerá responsável por todas as obrigações vinculadas à sua Play2sell Pay, mesmo após o seu encerramento.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='93'
          title={t('6.3 - Consequências do Encerramento') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='94'
          text={
            t(
              'O USUÁRIO reconhece que o encerramento de sua Play2sell Pay poderá também implicar a limitação ou término de acesso aos produtos e serviços adicionais oferecidos pela CONTRATADA. Qualquer serviço relacionado que esteja em uso pelo USUÁRIO poderá ser descontinuado sem possibilidade de reativação após o encerramento da conta'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='95'
          title={t('CLÁUSULA VII - DISPOSIÇÕES GERAIS') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='96'
          title={t('7.1 - Limitação de Responsabilidade') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='97'
          text={
            t(
              'A CONTRATADA, incluindo suas controladoras, subsidiárias, afiliadas, parceiros, diretores, funcionários e fornecedores, no Brasil ou no exterior, não será responsável por danos diretos, indiretos, lucros cessantes, perdas de oportunidade de negócio, danos morais ou qualquer outro prejuízo decorrente do uso da Play2sell Pay, independentemente de sua origem ou de culpa.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='98'
          title={t('7.2 - Serviços de Terceiros') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='99'
          text={
            t(
              'A CONTRATADA não se responsabiliza por falhas nos produtos ou serviços oferecidos por terceiros através da Play2sell Pay. O acesso contínuo a esses serviços não é garantido e pode sofrer interrupções. A CONTRATADA envidará esforços para que as transações sejam processadas de maneira tempestiva, mas não garante a duração do processamento.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='100'
          title={t('7.3 - Indenização') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='101'
          text={
            t(
              'O USUÁRIO concorda em defender, indenizar e manter indene a CONTRATADA e suas afiliadas de qualquer demanda, incluindo honorários advocatícios, decorrentes do descumprimento deste Termo ou do uso dos serviços.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='102'
          title={t('7.4 - Resolução de Disputas') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='103'
          text={
            t(
              'A CONTRATADA buscará resolver quaisquer disputas com o USUÁRIO de forma rápida e eficiente.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='104'
          title={t('7.5 - Disputas entre Usuários') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='105'
          text={
            t(
              'Em caso de disputa com outros usuários, o USUÁRIO isenta a CONTRATADA e suas afiliadas de todas as reclamações, demandas e danos relacionados a tais disputas.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='106'
          title={t('7.6 - Sub-rogação') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='107'
          text={
            t(
              'Se a CONTRATADA efetuar qualquer pagamento em nome do USUÁRIO, este concorda que a CONTRATADA poderá assumir os direitos e demandas relacionados a tal pagamento.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='108'
          title={t('7.7 - Obrigações Fiscais') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='109'
          text={
            t(
              'O USUÁRIO é responsável por declarar, reter, cobrar e remeter os tributos aplicáveis às transações. A CONTRATADA não é responsável pela determinação ou gestão de tributos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='110'
          title={t('7.8 - Declarações Regulatórias') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='111'
          text={
            t(
              'O USUÁRIO deve cumprir todas as obrigações declaratórias perante as autoridades competentes, incluindo o Banco Central e a Receita Federal, sobre os valores recebidos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='112'
          title={t('7.9 - Comunicações Eletrônicas') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='113'
          text={
            t(
              'O USUÁRIO concorda em receber comunicações relacionadas à sua Play2sell Pay e aos serviços por meios eletrônicos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='114'
          title={t('7.10 - Alterações do Termo') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='115'
          text={
            t(
              'A CONTRATADA reserva-se o direito de alterar este Termo a qualquer momento, publicando a nova versão no portal WEB ou aplicativo. A continuidade do uso dos serviços após tais alterações constituirá aceitação das novas condições.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='116'
          title={t('7.11 - Integridade do Acordo') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='117'
          text={
            t(
              'Este Termo, junto com outros contratos e políticas disponíveis no portal WEB, constitui o acordo integral entre as partes, substituindo quaisquer entendimentos anteriores.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='118'
          title={t('7.12 - Conflitos de Tradução') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='119'
          text={
            t(
              'Em caso de conflito entre traduções deste Termo, prevalecerá a versão em português.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='120'
          title={t('7.13 - Tolerância') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='121'
          text={
            t(
              'A tolerância de qualquer parte em exigir o cumprimento dos termos deste contrato não implicará em renúncia dos seus direitos.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='122'
          title={t('7.14 - Legislação Aplicável e Foro') || ''}
        />
        <CardText
          b_container={b_containerName}
          b_str='123'
          text={
            t(
              'Este Contrato é regido pelas leis do Brasil, sendo eleito o foro da comarca de São Paulo, SP, para resolver quaisquer disputas.'
            ) || ''
          }
        />
      </main>
      <Footer />
    </>
  );
};
export default TermosDeUsoP2SPay;
